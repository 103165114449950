import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import FilterAccordion from "../../../../components/Filters";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import Indicator from "../../../../components/Loader";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { initialState } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import dataObject, { periodView, priceRangeCompetitorFilterOrder, priceRangePepsiCoFilterOrder } from "../../../../mocks/priceRangePianoMock";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { enableCompetitorFilter } from "../../../../util/helper";
import MeasuresFilters, { generateIndexTextControl } from "../MeasuresFilters";
import { pricePianoDataLoader } from "../../store/PriceRangePiano.action";
import { CustomDropDownTitle } from "../../../../components/ProductFilters-v3/ProductFilters-v3";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import CustomDateRangePicker from "../../../../components/UI-components/DateRangePicker";
import { fetchFiltersData, fetchPeriodViewDates } from "../../../../util/services";
import { DateTime } from "luxon";
import { defaultCalendarDates } from "../../../../util/config";
import { PRICE_PIANO_PRODUCT_FILTER_QUERY } from "../../../../util/queries/priceRangePiano";
import CommonProductFilters from "../../../../components/CommonProductFilters";
import { IProductFilterConfigurations } from "../../../../types/common";
import { dialog } from "../../../../store/actions/dialog.action";

function defineIndexTextControl(indexes: string[]): { start: string | null; end: string | null }[] {
	// Sorts indexes in descending order
	indexes = indexes.sort((a, b) => b.localeCompare(a, undefined, { numeric: true }));
	return [
		{ start: indexes[0], end: null },
		...indexes.map((x, i) => ({
			start: i < indexes.length - 1 ? indexes[i + 1] : null,
			end: x,
		})),
	];
}

const TopFilters: React.FC<{ callback; defaultFilters? }> = ({ callback, defaultFilters }) => {
	const commonLoader = useSelector((state: any) => state.common.loader);
	const userDetail = useSelector((state: any) => state.User.data);
	const PricePianoDataLoader = useSelector((state: any) => state.PricePianoDataLoader);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [clearGeoFilterFlag, setClearGeoFilterFlag] = useState(false);
	const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
	const [selectedPepsicoFilters, setSelectedPepsicoFilters] = useState<any>({});
	const [selectedCompetitorFilters, setSelectedCompetitorFilters] = useState<any>({});
	const [isCountryManuallyChangedFlag, setIsCountryManuallyChangedFlag] = useState(false);

	const [selectedMeasureFilters, setSelectedMeasureFilters] = useState({
		variable1: dataObject.measuresFilter.variable1.defaultOption.value,
		SOMGroup: dataObject.measuresFilter.SOMGroup.defaultOption.value,
		SOMMeasure: dataObject.measuresFilter.SOMMeasure.defaultOption.value,
		APIUnits: dataObject.measuresFilter.APIUnits.defaultOption.value,
		indexes: generateIndexTextControl(dataObject.priceRanges[2].value),
	});
	const [isPeriodViewAPIFlags, setIsPeriodViewAPIFlags] = useState({
		isStartFlag: false,
		isCompleteFlag: true,
	});
	const [isFirstLoadPepsiFlag, setIsFirstLoadPepsiFlag] = useState(true);
	const [isFirstLoadCompetitorFlag, setIsFirstLoadCompetitorFlag] = useState(true);
	const [productFilterOptions, setProductFilterOptions] = useState<IProductFilterConfigurations[]>([]);
	const [competitorFilterOptions, setCompetitorFilterOptions] = useState<IProductFilterConfigurations[]>([]);
	const [selectedDate, setSelectedDate] = useState<any>([defaultCalendarDates.startDate, defaultCalendarDates.endDate]);

	useEffect(() => {
		if (Object.keys(defaultFilters).length > 0) {
			setSelectedGeoFilters(defaultFilters || initialState);
			setSelectedMeasureFilters({
				...selectedMeasureFilters,
				SOMMeasure: defaultFilters?.SOMMeasure || dataObject.measuresFilter.SOMMeasure.defaultOption.value,
				SOMGroup: defaultFilters?.SOMGroup || dataObject.measuresFilter.SOMGroup.defaultOption.value,
				APIUnits: defaultFilters?.APIUnits || dataObject.measuresFilter.APIUnits.defaultOption.value,
				variable1: defaultFilters?.variable1 || dataObject.measuresFilter.variable1.defaultOption.value,
				indexes:
					defaultFilters && _.isArray(defaultFilters?.indexes) && defaultFilters?.indexes.length > 0
						? defineIndexTextControl(defaultFilters.indexes)
						: generateIndexTextControl(dataObject.priceRanges[2].value),
			});
			if (defaultFilters.startDate && defaultFilters.endDate) setSelectedDate([new Date(defaultFilters.startDate), new Date(defaultFilters.endDate)]);
			if (defaultFilters.pepsi) {
				setSelectedPepsicoFilters({
					...selectedPepsicoFilters,
					category: defaultFilters.pepsi.category,
					segment: defaultFilters.pepsi.segment,
					brand: defaultFilters.pepsi.brand,
					subBrand: defaultFilters.pepsi.subBrand,
					packSize: defaultFilters.pepsi.packSize,
					permutation: defaultFilters.pepsi.permutation,
				});
			}
			if (defaultFilters.competitor) {
				setSelectedCompetitorFilters({
					...selectedCompetitorFilters,
					vendor: defaultFilters.competitor.vendor,
					category: defaultFilters.competitor.category,
					segment: defaultFilters.competitor.segment,
					brand: defaultFilters.competitor.brand,
					subBrand: defaultFilters.competitor.subBrand,
					packSize: defaultFilters.competitor.packSize,
					permutation: defaultFilters.competitor.permutation,
				});
			}
		}
	}, [defaultFilters]);

	useEffect(() => {
		if (selectedGeoFilters && selectedGeoFilters.country !== null && userDetail?.id) {
			fetchFilters({ country: selectedGeoFilters.country }, isCountryManuallyChangedFlag);
		}
	}, [selectedGeoFilters.country, userDetail?.id]);

	const handlePepsiSelections = (selectedValues) => {
		setIsFirstLoadPepsiFlag(false);
		setIsPeriodViewAPIFlags({ ...isPeriodViewAPIFlags, isStartFlag: true });
		setSelectedPepsicoFilters(selectedValues);
	};

	const handleCompetitorSelections = (selectedValues) => {
		setIsFirstLoadCompetitorFlag(false);
		setSelectedCompetitorFilters(selectedValues);
	};

	const clearFilter = () => {
		setClearGeoFilterFlag(true);
		setSelectedGeoFilters({ ...initialState });
		setSelectedMeasureFilters({
			...selectedMeasureFilters,
			variable1: dataObject.measuresFilter.variable1.defaultOption.value,
			SOMGroup: dataObject.measuresFilter.SOMGroup.defaultOption.value,
			SOMMeasure: dataObject.measuresFilter.SOMMeasure.defaultOption.value,
			APIUnits: dataObject.measuresFilter.APIUnits.defaultOption.value,
			indexes: generateIndexTextControl(dataObject.priceRanges[2].value),
		});
		setSelectedPepsicoFilters({});
		setSelectedCompetitorFilters({});
		setProductFilterOptions([]);
		setCompetitorFilterOptions([]);
	};

	const setSelectedGeoFiltersLogic = (e) => {
		setSelectedGeoFilters({ ...e });
		if (clearGeoFilterFlag) {
			setClearGeoFilterFlag(false);
		}
	};

	const onApplyFilter = async () => {
		dispatch(pricePianoDataLoader(true));
		callback({
			filters: {
				...selectedGeoFilters,
				...selectedMeasureFilters,
				indexes: selectedMeasureFilters.indexes.map((x) => x.start).filter((y) => y),
				pepsi: { ...selectedPepsicoFilters, vendor: ["PEPSICO"] },
				competitor: { ...selectedCompetitorFilters },
				startDate: selectedDate[0],
				endDate: selectedDate[1],
			},
		});
	};

	const disableApplyFilter = () => {
		const flag =
			!selectedGeoFilters.country ||
			!selectedGeoFilters.geoLevel2 ||
			!selectedPepsicoFilters.category ||
			(enableCompetitorFilter(selectedGeoFilters) && !selectedCompetitorFilters.category);
		return flag;
	};

	useEffect(() => {
		if (isPeriodViewAPIFlags.isStartFlag && isPeriodViewAPIFlags.isCompleteFlag) {
			setIsPeriodViewAPIFlags({ ...isPeriodViewAPIFlags, isStartFlag: false });
			onChangePeriodView(selectedGeoFilters.periodView[0]);
		}
	}, [isPeriodViewAPIFlags]);

	const onChangePeriodView = (periodView) => {
		if (periodView !== "na" && selectedGeoFilters.country && selectedGeoFilters.geoLevel && selectedGeoFilters.geoLevel2 && selectedPepsicoFilters.category) {
			setLoader(true);
			const payload = {
				period: periodView,
				country: selectedGeoFilters.country,
				geoLevel: selectedGeoFilters.geoLevel,
				geoLevel2: selectedGeoFilters.geoLevel2,
				category: selectedPepsicoFilters.category,
			};
			fetchPeriodViewDates("PriceRangePiano", payload)
				.then((res) => {
					const response = res.data;
					if (res.status === 200 && response?.startDate && response?.endDate) {
						const startDate = DateTime.fromString(response.startDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						const endDate = DateTime.fromString(response.endDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						setSelectedDate([startDate, endDate]);
						setIsPeriodViewAPIFlags({ ...isPeriodViewAPIFlags, isStartFlag: false, isCompleteFlag: true });
					} else {
						dispatch(dialog("Data is not available for selected Geo and Product filters!", "Period View", true));
					}
					setLoader(false);
				})
				.catch((e) => {
					dispatch(dialog(e.message, "Period View", true));
					setLoader(false);
				});
		}
	};

	const fetchFilters = (payload, isCountryManuallyChangedFlag) => {
		if (isCountryManuallyChangedFlag) {
			setSelectedPepsicoFilters({});
			setSelectedCompetitorFilters({});
		}
		if (selectedGeoFilters.country) {
			setLoader(true);
			payload = {
				...payload,
			};
			fetchFiltersData(
				"graphql",
				{
					country: selectedGeoFilters.country,
					...payload,
				},
				userDetail.id,
				PRICE_PIANO_PRODUCT_FILTER_QUERY
			)
				.then((response) => {
					if (response && response.data) {
						const productFilterConfig: IProductFilterConfigurations[] = [];
						const competitorFilterConfig: IProductFilterConfigurations[] = [];
						const filterObject = JSON.parse(response.data);
						filterObject
							.filter((object) => object.Manufacturer === "PEPSICO")
							.map((object) => {
								productFilterConfig.push({
									category: {
										label: object.category,
										value: object.category,
									},
									segment: {
										label: object.segment,
										value: object.segment,
									},
									brand: {
										label: object.brand,
										value: object.brand,
									},
									subBrand: {
										label: object.subbrand,
										value: object.subbrand,
									},
									packSize: {
										label: object.packsize,
										value: object.packsize,
									},
									permutation: {
										label: object.permutation,
										value: object.subbrandsize,
									},
									vendor: {
										label: object.Manufacturer,
										value: object.Manufacturer,
									},
								});
							});

						filterObject
							.filter((object) => object.Manufacturer !== "PEPSICO")
							.map((object) => {
								competitorFilterConfig.push({
									category: {
										label: object.category,
										value: object.category,
									},
									segment: {
										label: object.segment,
										value: object.segment,
									},
									brand: {
										label: object.brand,
										value: object.brand,
									},
									subBrand: {
										label: object.subbrand,
										value: object.subbrand,
									},
									packSize: {
										label: object.packsize,
										value: object.packsize,
									},
									permutation: {
										label: object.permutation,
										value: object.subbrandsize,
									},
									vendor: {
										label: object.Manufacturer,
										value: object.Manufacturer,
									},
								});
							});
						setProductFilterOptions(productFilterConfig);
						setCompetitorFilterOptions(competitorFilterConfig);
						setLoader(false);
					}
				})
				.catch((e) => {
					setLoader(false);
				});
		}
	};

	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<Indicator position="absolute" show={!commonLoader && (loader || PricePianoDataLoader.pricePianoDataLoader)} />
			<CardContent>
				<>
					<FilterAccordion title="Geo-Filters">
						<GeoFiltersV2
							data={{ ...geoFilterV2Config }}
							onChangeDate={(dt) => {
								setClearGeoFilterFlag(false);
							}}
							onSelectFilters={(e) => {
								setSelectedGeoFiltersLogic(e);
								setIsPeriodViewAPIFlags({ ...isPeriodViewAPIFlags, isStartFlag: true });
							}}
							clearFilter={clearGeoFilterFlag}
							apiPath="PriceRangePiano"
							showLoader={setLoader}
							defaultFilters={defaultFilters}
							showDatePicker={false}
							setIsCountryManuallyChangedFlag={setIsCountryManuallyChangedFlag}
						/>
					</FilterAccordion>

					<FilterAccordion title="Products Filters">
						<Grid container display="flex" alignItems="center" justifyContent="space-between">
							<Grid container spacing={2} columns={Object.keys(dataObject.productFilter).length + 1}>
								<Grid item xs={1}>
									<>
										<CustomDropDownTitle title={'Vendor <span style="color: #858c94;">(Pepsico)</span>'} />
										<Dropdown
											disabled={true}
											data={[{ label: "PEPSICO", value: "PEPSICO" }]}
											defaultOption={["PEPSICO"]}
											placeholder="Select"
											search={{ enable: false }}
										/>
									</>
								</Grid>
								<Grid item xs={Object.keys(dataObject.productFilter).length}>
									<CommonProductFilters
										filterData={productFilterOptions}
										onChange={handlePepsiSelections}
										data={dataObject.productFilter}
										defaultFilters={selectedPepsicoFilters || {}}
										filterOrder={priceRangePepsiCoFilterOrder}
										isAnchorFlag={false}
										isFirstLoadFlag={isFirstLoadPepsiFlag}
									/>
								</Grid>
							</Grid>
							<Grid xs={12} sm={12}>
								<CommonProductFilters
									filterData={competitorFilterOptions}
									onChange={handleCompetitorSelections}
									data={dataObject.competitorFilter}
									defaultFilters={selectedCompetitorFilters || {}}
									filterOrder={priceRangeCompetitorFilterOrder}
									isAnchorFlag={false}
									isFirstLoadFlag={isFirstLoadCompetitorFlag}
								/>
							</Grid>
						</Grid>
					</FilterAccordion>
					<FilterAccordion title="Date Filters">
						<Grid container spacing={2} sx={{ mb: 4 }}>
							<Grid item xs={12} md={2}>
								<DropdownTitle>{periodView.title}</DropdownTitle>
								<Dropdown
									data={periodView.options}
									onChange={(periodView) => {
										setSelectedGeoFilters({ ...selectedGeoFilters, periodView });
										onChangePeriodView(periodView[0]);
									}}
									defaultOption={selectedGeoFilters.periodView || ["empty"]}
									placeholder={periodView.placeholder}
									sort={{ enable: false }}
									search={{ enable: false }}
								/>
							</Grid>
							<Grid item xs={12} md={2} sm={2} lg={2}>
								<DropdownTitle>Date Range</DropdownTitle>
								<CustomDateRangePicker defaultDate={selectedDate} callback={setSelectedDate} disabled={selectedGeoFilters.periodView[0] !== "na"} />
							</Grid>
						</Grid>
					</FilterAccordion>
					<FilterAccordion title="Measure Filters">
						<MeasuresFilters callback={setSelectedMeasureFilters} selectedMeasuresFilters={selectedMeasureFilters} />
					</FilterAccordion>
					<Grid className="p-l-16">
						<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
							Clear Filter
						</OrangeBtn>
						<PrimaryBtn disabled={disableApplyFilter()} color="primary" onClick={onApplyFilter}>
							Apply Filter
						</PrimaryBtn>
					</Grid>
				</>
			</CardContent>
		</Card>
	);
};

export default TopFilters;
