import React, { useEffect, useRef, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import Dashboard from "../../../../components/Dashboard";
import dataObject from "../../../../mocks/revenueMap";
import { BlackTitle, NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { uniqueColors } from "../../../../util/helper";
import MultiLevelTable from "../../../../components/MultiLevelTable";
import { getQuadrantIntensity } from "../../service";
import { quadrantIntensityLoader } from "../../store/revenueMap.action";
import { toast } from "../../../../store/actions/toast.action";
import BubbleChart from "../BubbleChart";
import { chartConfig } from "../QuadrantIntensityGraph/chartConfig";

function getAverageValue(tableData) {
	let iterator = 0,
		somTotal = 0,
		xValueTotal = 0,
		yValueTotal = 0;
	tableData.map((data) => {
		somTotal += data.somValue;
		xValueTotal += data.xValue;
		yValueTotal += data.yValue;
		iterator++;
	});
	return [
		{ label: "Average Value", value: "Average Value" },
		{ label: (somTotal / iterator).toFixed(2), value: parseFloat((somTotal / iterator).toFixed(2)) },
		{ label: (xValueTotal / iterator).toFixed(2), value: parseFloat((xValueTotal / iterator).toFixed(2)) },
		{ label: (yValueTotal / iterator).toFixed(2), value: parseFloat((yValueTotal / iterator).toFixed(2)) },
	];
}

function getQuadChildData(parentLevelData, childLevelData, childColumn) {
	if (childColumn && childLevelData) {
		const filteredChildLevelData = childLevelData.filter((data) => data.parentName === parentLevelData.name);
		return filteredChildLevelData;
	} else {
		return null;
	}
}

const QuadrantIntensityGraph: React.FC<{ filters? }> = ({ filters }) => {
	const chartRef = useRef(null);
	const dispatch = useDispatch();
	const [quadrantIntensityChartData, setQuadrantIntensityChartData] = useState<any>([]);
	const [skeleton, setSkeleton] = useState(true);
	const [otherData, setOtherData] = useState(0);
	const [unitInfo, setUnitInfo] = useState({ xUnit: null, yUnit: null });
	const [minXVal, setMinXVal] = useState(false);
	const [minYVal, setMinYVal] = useState(false);
	const [legendColors, setLegendColors] = useState([]);

	const [level, setLevel] = useState("");
	const [hierarchy, setHierarchy] = useState({});
	const [multiLevelTableData, setMultiLevelTableData] = useState({});

	const [tableFooters, setTableFooters] = useState<any>([]);
	const [tableHeaders, setTableHeaders] = useState<any>([]);

	const fetchQuadrantIntensityData = async () => {
		let colors: any = [];
		const response = await getQuadrantIntensity(filters);
		let averageColumn: string = "";
		let childColumn: string = "";
		let childColumnDisplayName: string = "";
		if (response?.data) {
			switch (filters.viewType) {
				case "manufacturer":
					setHierarchy({
						vendor: { responseKeys: ["name", "somValue", "xValue", "yValue"] },
						segment: { filterBy: ["vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						brands: { filterBy: ["segment", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						subBrands: { filterBy: ["brands", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						packSizes: { filterBy: ["subBrands", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
					});
					[
						{ child: "segment", parent: "vendor" },
						{ child: "brands", parent: "segment" },
						{ child: "subBrands", parent: "brands" },
						{ child: "packSizes", parent: "subBrands" },
					].map((d) => {
						response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
					});
					averageColumn = "vendor";
					childColumn = "segment";
					childColumnDisplayName = "Segment";
					break;
				case "packageSize":
					setHierarchy({
						packSizes: { responseKeys: ["name", "somValue", "xValue", "yValue"] },
						vendor: { filterBy: ["packSizes"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						segment: { filterBy: ["packSizes", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						brands: { filterBy: ["packSizes", "segment", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						subBrands: { filterBy: ["packSizes", "brands", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
					});
					[
						{ child: "vendor", parent: "packSizes" },
						{ child: "segment", parent: "vendor" },
						{ child: "brands", parent: "segment" },
						{ child: "subBrands", parent: "brands" },
					].map((d) => {
						response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
					});
					averageColumn = "packSizes";
					childColumn = "vendor";
					childColumnDisplayName = "Vendor";
					break;
				case "brand":
					setHierarchy({
						brands: { responseKeys: ["name", "somValue", "xValue", "yValue"] },
						subBrands: { filterBy: ["brands"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						packSizes: { filterBy: ["subBrands"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
					});
					[
						{ child: "subBrands", parent: "brands" },
						{ child: "packSizes", parent: "subBrands" },
					].map((d) => {
						response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
					});
					averageColumn = "brands";
					childColumn = "subBrands";
					childColumnDisplayName = "Sub Brands";
					break;
				case "brandSize":
					setHierarchy({
						brandSize: { responseKeys: ["name", "somValue", "xValue", "yValue"] },
						subBrands: { filterBy: ["brandSize"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
					});
					[{ child: "subBrands", parent: "brandSize" }].map((d) => {
						response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
					});
					averageColumn = "brandSize";
					childColumn = "subBrands";
					childColumnDisplayName = "Sub Brands";
					break;
				case "subBrand":
					setHierarchy({
						subBrands: { responseKeys: ["name", "somValue", "xValue", "yValue"] },
						packSizes: { filterBy: ["subBrands"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
					});
					[{ child: "packSizes", parent: "subBrands" }].map((d) => {
						response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
					});
					averageColumn = "subBrands";
					childColumn = "packSizes";
					childColumnDisplayName = "Pack Sizes";
					break;
				case "subBrandSize":
					setHierarchy({
						subBrandSize: { responseKeys: ["name", "somValue", "xValue", "yValue"] },
					});
					averageColumn = "subBrandSize";
					break;
				case "segment":
					setHierarchy({
						segment: { responseKeys: ["name", "somValue", "xValue", "yValue"] },
						vendor: { filterBy: ["segment"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						brands: { filterBy: ["segment", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						subBrands: { filterBy: ["brands", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
						packSizes: { filterBy: ["subBrands", "vendor"], responseKeys: ["name", "somValue", "xValue", "yValue"] },
					});
					[
						{ child: "vendor", parent: "segment" },
						{ child: "brands", parent: "segment" },
						{ child: "subBrands", parent: "brands" },
						{ child: "packSizes", parent: "subBrands" },
					].map((d) => {
						response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
					});
					averageColumn = "segment";
					childColumn = "vendor";
					childColumnDisplayName = "Vendor";
					break;
				default:
					break;
			}
			setTableFooters(getAverageValue(response.data.tableData[averageColumn]));
			setMultiLevelTableData({ ...response.data.tableData });
		}
		let grandTotal: any = { sumOfSomValue: 0, sumOfxValue: [], sumOfyValue: [] };
		let minXValFlag = false,
			minYValFlag = false;
		if (response?.data && response.data.values) {
			let formatValues: any = response.data.values?.map((item, index) => {
				const childData = getQuadChildData(item, response.data.tableData[childColumn], childColumn);
				if (!minXValFlag) {
					minXValFlag = item.xValue < 0;
				}
				if (!minYValFlag) {
					minYValFlag = item.yValue < 0;
				}

				grandTotal.sumOfSomValue += item.somValue;
				if (item.xValue !== 0) {
					grandTotal.sumOfxValue.push(item.xValue);
				}

				if (item.yValue !== 0) {
					grandTotal.sumOfyValue.push(item.yValue);
				}
				colors.push(uniqueColors[index]);
				return {
					name: item.name,
					z: item.somValue,
					x: item.xValue,
					y: item.yValue,
					childData: childData,
					childColumn: { label: childColumnDisplayName, value: childColumn },
				};
			});
			setMinXVal(minXValFlag);
			setMinYVal(minYValFlag);
			setLegendColors(colors);
			setQuadrantIntensityChartData([...formatValues]);

			setSkeleton(false);
			setOtherData(response.data?.others);
			setUnitInfo({ xUnit: response.data?.xUnit, yUnit: response.data?.yUnit });
		}
		if (response.error) {
			dispatch(toast("quadIntensity" + response.error, true, 2000, "error"));
		}
		dispatch(quadrantIntensityLoader(false));
	};

	useEffect(() => {
		if (filters && filters.country) {
			setQuadrantIntensityChartData([]);
			setSkeleton(true);
			fetchQuadrantIntensityData();
			switch (filters.viewType) {
				case "manufacturer":
					setLevel("vendor");
					break;
				case "packageSize":
					setLevel("packSizes");
					break;
				case "brand":
					setLevel("brands");
					break;
				case "brandSize":
					setLevel("brandSize");
					break;
				case "subBrand":
					setLevel("subBrands");
					break;
				case "subBrandSize":
					setLevel("subBrandSize");
					break;
				case "segment":
					setLevel("segment");
					break;
				default:
					break;
			}
			let newTableHeaders: any = [
				dataObject.viewType.find((x) => x.value === filters.viewType),
				dataObject.viewSize.find((x) => x.value === filters.viewSize),
				[...dataObject.sellIn, ...dataObject.sellOut].find((x) => x.value === filters.viewX),
				[...dataObject.sellIn, ...dataObject.sellOut].find((x) => x.value === filters.viewY),
			];
			setTableHeaders([...newTableHeaders]);
		}
	}, [filters]);

	const getTitle = (value) => {
		let title = "";
		dataObject.sellIn.map((x) => {
			if (x.value === value) title = x.label;
		});
		dataObject.sellOut.map((x) => {
			if (x.value === value) title = x.label;
		});
		return title;
	};

	return (
		<Card className="m-b-20">
			<CardContent>
				<Grid>
					{skeleton ? (
						<Dashboard title="Quadrant Intensity Graph" chartRef={null} id="revenue-map-quadrant-intensity-graph" showSkeleton={true}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Skeleton variant="rectangular" height={400} />
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Skeleton variant="rectangular" height={400} />
								</Grid>
							</Grid>
						</Dashboard>
					) : quadrantIntensityChartData?.length > 0 ? (
						<Dashboard title="Quadrant Intensity Graph" showSkeleton={false} chartRef={chartRef} id="revenue-map-quadrant-intensity-graph">
							<Grid container alignItems="center" justifyContent="center" textAlign={"center"} mt={2}>
								<Grid item md={12}>
									<BlackTitle variant="h5">
										The Unit Information shown by variables is: X: {unitInfo.xUnit}, Y: {unitInfo.yUnit}
									</BlackTitle>
								</Grid>
								<Grid item md={8}>
									<Box sx={{ backgroundColor: "#E8E8E8" }} m={2}>
										<BlackTitle p={2}>
											{`Size: ${dataObject.viewSize.find((x) => x.value === filters.viewSize)?.label} -> VAR TYPE: ${
												dataObject.viewType.find((x) => x.value === filters.viewType)?.label
											}
                      -> VAR X: ${getTitle(filters.viewX)} -> VAR Y: ${getTitle(filters.viewY)} -> VAR TOP: ${filters.viewTop}`}
										</BlackTitle>
									</Box>
								</Grid>
								<Grid item md={8}>
									<Box m={2}>
										<BlackTitle p={2}>
											OTHERS NOT IN TOP {filters.viewTop} = <span style={{ color: "blue" }}>{otherData.toFixed(2)}%</span>
										</BlackTitle>
									</Box>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item sm={12}>
									<BubbleChart
										chartRef={chartRef}
										filters={filters}
										chartConfig={chartConfig}
										data={[...quadrantIntensityChartData]}
										draggableBubble={false}
										draggableLines={true}
										minXVal={minXVal}
										minYVal={minYVal}
										legendColors={legendColors}
										id="revenue-map-quadrant-intensity-graph"
									/>
								</Grid>
								<Grid item sm={12}>
									<Grid container spacing={0}>
										{multiLevelTableData && (
											<MultiLevelTable
												data={{ ...multiLevelTableData }}
												tableHeader={tableHeaders}
												tableFooter={tableFooters}
												showSkeleton={false}
												level={level}
												hierarchy={hierarchy}
												style={{ height: 500, overflow: "auto" }}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Dashboard>
					) : (
						<Dashboard title="Quadrant Intensity Graph" chartRef={null} showActionButton={false} id="revenue-map-quadrant-intensity-graph">
							<Grid container display="flex" justifyContent="center" alignItems="center" style={{ height: 400 }}>
								<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
							</Grid>
						</Dashboard>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};
export default QuadrantIntensityGraph;
