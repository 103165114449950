import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Skeleton } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandIcon } from "./Filters.Styled";
import { BlackTitle } from "../../../src/styles/Common.Styled";

const useStyles = makeStyles((theme) => ({
	m_0: { margin: "0px !important", boxShadow: "none !important" },
}));

const FilterAccordion: React.FC<{ title?; children; showSkeleton?: boolean }> = ({ title, children, showSkeleton = false }) => {
	const classes = useStyles();
	return (
		<Accordion data-testid="accordion" className={classes.m_0} key={`${title}-accordion`} sx={{ "&:before": { backgroundColor: "#000", opacity: 0.1 } }}>
			<AccordionSummary expandIcon={<ExpandIcon />}>
				{showSkeleton ? (
					<Skeleton variant="rectangular" width={210} height={40} />
				) : (
					<BlackTitle className={"f-w-5"}>
						<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: title }}></span>
					</BlackTitle>
				)}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default FilterAccordion;
