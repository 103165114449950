import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import $ from "jquery";
import _ from "lodash";
import HighchartContainer from "../../../../components/HighchartContainer";
import dataObject from "../../../../mocks/revenueMap";
import { plotElementsOnChart } from "../../../../util/helper";

const BubbleChart: React.FC<{
	chartRef?;
	filters?;
	chartConfig;
	draggableBubble?;
	draggableLines?;
	data;
	onDrop?;
	id?;
	hideQuadrants?;
	hideDraggableLines?;
	minXVal?: boolean;
	minYVal?: boolean;
	legendColors;
}> = ({
	chartRef,
	filters,
	chartConfig,
	draggableBubble,
	draggableLines,
	data,
	onDrop,
	id,
	hideQuadrants,
	hideDraggableLines,
	minXVal,
	minYVal,
	legendColors,
}) => {
	const [boxHeight, setBoxHeight] = useState(0);
	const [boxWidth, setBoxWidth] = useState(0);
	const [refs, setRefs] = useState([]);
	const [quadrantColor, setQuadrantColor] = useState();
	const setMinMaxValToChart = (chart) => {
		let maxXValue = 0,
			maxYValue = 0;
		data.map((item) => {
			if (minXVal) {
				const x = item.x < 0 ? item.x * -1 : item.x;
				if (x > maxXValue) maxXValue = x + (x / data.length) * 2;
			}
			if (minYVal) {
				const y = item.y < 0 ? item.y * -1 : item.y;
				if (y > maxYValue) maxYValue = y + (y / data.length) * 2;
			}
		});

		if (minXVal) {
			chart.xAxis[0].update({ min: maxXValue * -1, max: maxXValue });
		}

		if (minYVal) {
			chart.yAxis[0].update({ min: maxYValue * -1, max: maxYValue });
		}
	};

	const onWindowResize = () => {
		let flag = true;
		if (flag) {
			setTimeout(() => {
				flag = true;
			}, 500);
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				setTimeout(() => {
					if (!hideDraggableLines) {
						chart.setSize(null, window.innerHeight - 100);
						draggablePlotLine(chart.xAxis[0], "x");
						draggablePlotLine(chart.yAxis[0], "y");
						chart.redraw();
					}
				}, 500);
			}
			flag = false;
		}
	};

	useEffect(() => {
		//Resetting the chart tooltip font size on component mount
		if (chartRef?.current?.chart?.tooltip?.options?.style?.fontSize) delete chartRef.current.chart.tooltip.options.style.fontSize;

		if (id === "revenue-map-quadrant-intensity-graph") {
			window.addEventListener("storage", (dt) => {
				let dashbaordData: any = JSON.parse(localStorage.getItem("mmpw"));
				if (dashbaordData && dashbaordData[id]) {
					if (dashbaordData[id].chartConfig && dashbaordData[id].chartConfig.chartBackground) {
						setQuadrantColor(dashbaordData[id].chartConfig.chartBackground);
					}
					if (
						(dashbaordData[id].series &&
							_.isArray(dashbaordData[id].series) &&
							dashbaordData[id].series.findIndex((x) => x.type === "xAxisPlotLine" || x.type === "yAxisPlotLine") !== -1) ||
						(dashbaordData[id] && dashbaordData[id].chartConfig && !_.isEmpty(dashbaordData[id].chartConfig.axisMinMax))
					) {
						if (chartRef && chartRef.current && chartRef.current.chart) {
							const chart = chartRef.current.chart;
							if (dashbaordData[id]?.chartConfig?.axisMinMax?.xAxis[0]?.min && dashbaordData[id]?.chartConfig?.axisMinMax?.yAxis[0]?.min) {
								plotLine(
									chart,
									parseInt(dashbaordData[id]?.chartConfig?.axisMinMax?.xAxis[0]?.min),
									parseInt(dashbaordData[id]?.chartConfig?.axisMinMax?.yAxis[0]?.min)
								);
							}
							setTimeout(() => {
								if (!hideDraggableLines) {
									draggablePlotLine(chart.xAxis[0], "x");
									draggablePlotLine(chart.yAxis[0], "y");
								}
							}, 500);
						}
					}
				}
			});
		}
		window.addEventListener("resize", onWindowResize);
		return () => {
			window.removeEventListener("resize", () => {});
			window.removeEventListener("storage", () => {});
		};
	}, []);

	const plotLine = (chart, xValue, yValue) => {
		for (let plotLineBand of chart.xAxis[0].plotLinesAndBands) {
			if (plotLineBand.id === "x") {
				const color = plotLineBand.options.color;
				chart.xAxis[0].removePlotLine("x");
				if (!hideDraggableLines) {
					chart.xAxis[0].addPlotLine({
						id: "x",
						color: color || "#faa",
						width: 3,
						value: xValue,
						zIndex: 3,
					});
				}
			}
		}
		for (let plotLineBand of chart.yAxis[0].plotLinesAndBands) {
			if (plotLineBand.id === "y") {
				const color = plotLineBand.options.color;
				chart.yAxis[0].removePlotLine("y");
				if (!hideDraggableLines) {
					chart.yAxis[0].addPlotLine({
						id: "y",
						color: color || "#faa",
						width: 3,
						value: yValue,
						zIndex: 3,
					});
				}
			}
		}
	};

	const plotChart = () => {
		if (chartRef && chartRef.current && chartRef.current.chart && data?.length > 0) {
			const chart = chartRef.current.chart;
			chart.setSize(null, window.innerHeight - 100);
			chart.update({ colors: legendColors });
			if (minXVal || minYVal) {
				setMinMaxValToChart(chart);
			}
			while (chart.series.length) {
				chart.series[0].remove();
			}
			data.map((item) => {
				draggableBubble
					? chart.addSeries(
							{
								name: _.isArray(item) ? item[0].name : item.name,
								lineWidth: _.isArray(item) && item.length > 1 ? 2 : 0,
								data: _.isArray(item) ? item : [{ ...item }],
								dragDrop: {
									draggableX: false,
									draggableY: true,
								},
								point: {
									events: {
										drop: onDrop,
									},
								},
							},
							false
					  )
					: chart.addSeries(
							{
								name: _.isArray(item) ? item[0].name : item.name,
								lineWidth: _.isArray(item) && item.length > 1 ? 2 : 0,
								data: _.isArray(item) ? item : [{ ...item }],
								dragDrop: {
									draggableX: false,
									draggableY: false,
								},
							},
							false
					  );
			});

			setBoxHeight(chart.plotBox.height / 2.0);
			setBoxWidth(chart.plotBox.width / 2.0);
			const xMax = chart.xAxis[0].max + chart.xAxis[0].min;
			const yMax = chart.yAxis[0].max + chart.yAxis[0].min;
			const xAxisLineValue = minXVal ? xMax / 2 : 0;
			const yAxisLineValue = minYVal ? yMax / 2 : 0;
			if (filters?.viewX && filters?.viewY) {
				try {
					dataObject.sellIn.map((x) => {
						if (x.value === filters?.viewY) chart.yAxis[0].setTitle({ text: x.label });
						if (x.value === filters?.viewX) chart.xAxis[0].setTitle({ text: x.label });
					});
					dataObject.sellOut.map((x) => {
						if (x.value === filters?.viewY) chart.yAxis[0].setTitle({ text: x.label });
						if (x.value === filters?.viewX) chart.xAxis[0].setTitle({ text: x.label });
					});
					dataObject.ViewXLocal.map((x) => {
						if (x.value === filters?.viewY) chart.yAxis[0].setTitle({ text: x.label });
						if (x.value === filters?.viewX) chart.xAxis[0].setTitle({ text: x.label });
					});
					dataObject.ViewYLocal.map((x) => {
						if (x.value === filters?.viewY) chart.yAxis[0].setTitle({ text: x.label });
						if (x.value === filters?.viewX) chart.xAxis[0].setTitle({ text: x.label });
					});
				} catch (e) {}
			}
			plotLine(chart, xAxisLineValue, yAxisLineValue);
			setTimeout(() => {
				if (!hideDraggableLines) {
					draggablePlotLine(chart.xAxis[0], "x");
					draggablePlotLine(chart.yAxis[0], "y");
				}
			}, 500);
			chart.redraw();
		}
	};

	useEffect(() => {
		if (chartRef && filters && data && data.length) {
			plotChart();
			const chart = chartRef.current.chart;
			let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
			if (dashbaordData && dashbaordData[id]) {
				plotElementsOnChart(dashbaordData[id], chart, id);
			}
		}
	}, []);

	useEffect(() => {
		if (boxHeight && boxWidth) {
			const chart = chartRef.current.chart;
			let fullHeight = chartRef.current.chart.xAxis[0].height;
			let fullWidth = chartRef.current.chart.xAxis[0].width;
			const width = chart.inverted ? (boxHeight / fullHeight) * fullWidth : boxWidth;
			const height = chart.inverted ? ((fullWidth - boxWidth) / fullWidth) * fullHeight : boxHeight;
			if (!hideQuadrants) {
				if (quadrantColor) {
					plotQuadrant(chart, width, height, quadrantColor);
				} else {
					plotQuadrant(chart, width, height);
				}
			}
		}
	}, [boxHeight, boxWidth, quadrantColor]);

	const plotQuadrant = (chart, width, height, color?) => {
		let fullHeight = chartRef.current.chart.xAxis[0].height;
		let fullWidth = chartRef.current.chart.xAxis[0].width;

		if (refs && refs.length > 0) {
			refs.map((ref: any) => {
				if (ref) ref.destroy();
			});
			setRefs([]);
		}

		let references: any = [];
		let ref;
		ref = chart.renderer
			.rect(chart.plotBox.x, chart.plotBox.y, fullWidth, height, 1)
			.attr({
				fill: color || "#fff5fa",
				zIndex: 0,
			})
			.add();

		references.push(ref);

		ref = chart.renderer
			.rect(chart.plotBox.x, chart.plotBox.y, fullWidth - width, height, 1)
			.attr({
				fill: color || "#f4fcff",
				zIndex: 0,
			})
			.add();
		references.push(ref);

		ref = chart.renderer
			.rect(chart.plotBox.x, chart.plotBox.y + height, fullWidth, fullHeight - height, 1)
			.attr({
				fill: color || "#f7fffb",
				zIndex: 0,
			})
			.add();
		references.push(ref);

		ref = chart.renderer
			.rect(chart.plotBox.x, chart.plotBox.y + height, fullWidth - width, fullHeight - height, 1)
			.attr({
				fill: color || "#eff5fd",
				zIndex: 0,
			})
			.add();
		references.push(ref);
		setRefs(references);
	};

	const draggablePlotLine = (axis, plotLineId) => {
		let clickX, clickY;

		const getPlotLine = () => {
			for (let plotLineBand of axis.plotLinesAndBands) {
				if (plotLineBand.id === plotLineId) {
					return plotLineBand;
				}
			}
		};

		const getValue = function () {
			const plotLine = getPlotLine();
			const translation = axis.horiz ? plotLine.svgElem.translateX : plotLine.svgElem.translateY;
			let new_value = axis.toValue(translation) - axis.toValue(0) + plotLine.options.value;
			new_value = Math.max(axis.min, Math.min(axis.max, new_value));
			return new_value;
		};

		const drag_start = function (e) {
			$(document).bind({
				"mousemove.line": drag_step,
				"mouseup.line": drag_stop,
			});
			let plotLine = getPlotLine();
			clickX = e.pageX - plotLine.svgElem.translateX;
			clickY = e.pageY - plotLine.svgElem.translateY;
			if (plotLine.options.onDragStart) {
				plotLine.options.onDragStart(getValue());
			}
		};

		const drag_step = function (e) {
			let plotLine = getPlotLine();
			let new_translation = axis.horiz ? e.pageX - clickX : e.pageY - clickY;
			let new_value = axis.toValue(new_translation) - axis.toValue(0) + plotLine.options.value;
			new_value = Math.max(axis.min, Math.min(axis.max, new_value));
			new_translation = axis.toPixels(new_value + axis.toValue(0) - plotLine.options.value);
			plotLine.svgElem.translate(axis.horiz ? new_translation : 0, axis.horiz ? 0 : new_translation);

			if (plotLine.options.onDragChange) {
				plotLine.options.onDragChange(new_value);
			}
		};

		const drag_stop = function () {
			const chart = chartRef.current.chart;

			$(document).unbind(".line");
			let plotLine = getPlotLine();
			let plotLineOptions = plotLine.options;
			if (plotLine.svgElem.hasOwnProperty("translateX")) {
				plotLineOptions.value = getValue();
				axis.removePlotLine(plotLineOptions.id);
				axis.addPlotLine(plotLineOptions);

				if (plotLineOptions.onDragFinish) {
					plotLineOptions.onDragFinish(plotLineOptions.value);
				}
			}

			let xAxisMin = chart.xAxis[0].min;
			let yAxisMin = chart.yAxis[0].min;
			let calculatedWidth = axis.width - ((plotLineOptions.value - xAxisMin) * axis.width) / (axis.max - xAxisMin);
			let calculatedHeight = axis.height - ((plotLineOptions.value - yAxisMin) * axis.height) / (axis.max - yAxisMin);
			if (plotLineOptions.id === "x") setBoxWidth(calculatedWidth);
			if (plotLineOptions.id === "y") setBoxHeight(calculatedHeight);

			if (draggableLines) getPlotLine().svgElem.css({ cursor: "pointer" }).translate(0, 0).on("mousedown", drag_start);
		};
		drag_stop();
	};

	return (
		<HighchartContainer chartRef={chartRef} id="revenue-map-quadrant-intensity-graph">
			<HighchartsReact highcharts={Highcharts} options={chartConfig} ref={chartRef} />
		</HighchartContainer>
	);
};

export default BubbleChart;
