import { title } from "process";
import { DIALOG } from "../actions/constants";

let intiState = {
	open: false,
	title: "",
	msg: "",
};

const dialog = (state = intiState, action) => {
	if (action.type === DIALOG)
		return {
			...state,
			...action.payload,
			open: action.payload.open,
			title: action.payload.title,
			msg: action.payload.msg,
		};
	else return state;
};

export default dialog;
