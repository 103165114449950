import React, { useEffect, useState } from "react";
import AppMenu from "./components/AppMenu";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../themes/theme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@material-ui/core/CssBaseline";

import Appbar from "./components/Appbar/Appbar";
import { Drawer, FlotButton, DrawerHeader, MainContainer } from "./Layout.Styled";
import { openSidebar } from "../store/actions";
import AccessBoundary from "../components/AccessBoundary/AccessBoundary";

const Layout: React.FC<{ children }> = ({ children }) => {
	const dispatch = useDispatch();
	const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
	const open = useSelector((state: any) => state.common.sidebarOpen);
	const handleDrawerToggle = () => {
		dispatch(openSidebar(!open));
	};
	const userData = useSelector((state: any) => state.User);
	const [userDataUpdateFlag, setUserDataUpdateFlag] = useState(false);
	useEffect(() => {
		if (userData.loading) {
			setUserDataUpdateFlag(true);
		}
	}, [userData.loading]);

	return (
		<ThemeProvider theme={theme.darkmode ? darkTheme : lightTheme}>
			<CssBaseline />
			<Box sx={{ display: "flex" }}>
				<Appbar />
				{userDataUpdateFlag && !userData.loading && userData.data === null ? (
					<AccessBoundary />
				) : (
					<>
						<Drawer variant="permanent" open={open}>
							<FlotButton onClick={handleDrawerToggle} data-testid="drawerToggleButton" open={open}>
								{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
							</FlotButton>
							<DrawerHeader />
							<AppMenu drawerState={open} />
						</Drawer>
						<MainContainer sx={{ flexGrow: 1, p: 3 }}>
							<DrawerHeader />
							<Box marginBottom={10}>{children}</Box>
						</MainContainer>
					</>
				)}
			</Box>
		</ThemeProvider>
	);
};

export default Layout;
