import { DIALOG } from "./constants";

export const dialog = (msg: string, title: string, open?: boolean) => {
	return {
		type: DIALOG,
		payload: {
			msg,
			title,
			open,
		},
	};
};
