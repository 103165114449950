import { IFilterPayload, RenvenueMapQuadIntensityPayload, RenvenueMapViewTypePayload } from "../../types/common";

/* Creates a GraphQL query to quadrant intensity graph data for revenue map dashboard.
 * @param {RenvenueMapQuadIntensityPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const REVENUEMAP_QUAD_INTENSITY_QUERY = (payload: RenvenueMapQuadIntensityPayload) => {
	return {
		query: `{
      quadIntensity(
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        channel: ${payload.channel ? JSON.stringify(payload.channel) : null}
        periodView: "${payload.periodView}"
        viewTop: ${payload.viewTop}
        data: "${payload.data}"
        viewX: "${payload.viewX}"
        viewY: "${payload.viewY}"
        viewSize: "${payload.viewSize}"
        viewType: "${payload.viewType}"
        category:  ${payload.category ? JSON.stringify(payload.category) : null}
        vendor: ${payload.vendor ? JSON.stringify(payload.vendor) : null}
        segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
        brand:  ${payload.brand ? JSON.stringify(payload.brand) : null}
        subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
        packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
        date: "${payload.date}"
      ) 
      {
        others
        xUnit
        yUnit
        values {
          name
          xValue
          yValue
          somValue
        }
        tableData {
          vendor {
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
          category {
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
          segment {
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
          brands {
            vendor
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
          subBrands {
            vendor
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
          packSizes {
            vendor
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
          brandSize {
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
          subBrandSize {
            parentName
            name
            xValue
            yValue
            somValue
            packSizes
          }
        }
      }
    }`,
	};
};

/* Creates a GraphQL query to revenue map by manufacturer graph data for revenue map dashboard.
 * @param {RenvenueMapViewTypePayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const REVENUEMAP_VIEW_TYPE_QUERY = (payload: RenvenueMapViewTypePayload) => {
	return {
		query: `{
      ViewType(
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        channel: ${payload.channel ? JSON.stringify(payload.channel) : null}
        periodView: "${payload.periodView}"
        viewTop: ${payload.viewTop}
        data: "${payload.data}"
        viewX: "${payload.viewX}"
        viewY: "${payload.viewY}"
        viewSize: "${payload.viewSize}"
        viewType: "${payload.viewType}"
        category:  ${payload.category ? JSON.stringify(payload.category) : null}
        vendor: ${payload.vendor ? JSON.stringify(payload.vendor) : null}
        segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
        brand:  ${payload.brand ? JSON.stringify(payload.brand) : null}
        subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
        packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
        date: "${payload.date}"
        startDate: "${typeof payload.startDate === "string" ? new Date(payload.startDate) : payload.startDate.toISOString()}"
        endDate: "${typeof payload.endDate === "string" ? new Date(payload.endDate) : payload.endDate.toISOString()}"
        views: ${payload.views ? JSON.stringify(payload.views) : null}
        variables: ${payload.variables ? JSON.stringify(payload.variables) : null}
      ) 
      {
        viewType
    currentPeriod {
      salesAmountVsYAGO
      perSalesAmountVsYAGO
      salesUnitVsYAGO
      perSalesUnitVsYAGO
      salesVolumeVsYAGO
      perSalesVolumeVsYAGO
      profitByUnit
      profitByVolume
      priceByVolume
      priceByUnitSDV
      dandAByUnit
      cogSByUnit
      cogSByVolume
      gramsPerUnit
      growthSOMByYear
      perGrowthSOMByYear
      priceByUnit
      salesAmount
      salesUnits
      salesVolume
      somSalesAmount
      somSalesUnits
      somSalesVolume
    }
    lastPeriod {
      salesAmountVsYAGO
      perSalesAmountVsYAGO
      salesUnitVsYAGO
      perSalesUnitVsYAGO
      salesVolumeVsYAGO
      perSalesVolumeVsYAGO
      profitByUnit
      profitByVolume
      priceByVolume
      priceByUnitSDV
      dandAByUnit
      cogSByUnit
      cogSByVolume
      gramsPerUnit
      growthSOMByYear
      perGrowthSOMByYear
      priceByUnit
      salesAmount
      salesUnits
      salesVolume
      somSalesAmount
      somSalesUnits
      somSalesVolume
    }
    periodCompare {
      salesAmountVsYAGO
      perSalesAmountVsYAGO
      salesUnitVsYAGO
      perSalesUnitVsYAGO
      salesVolumeVsYAGO
      perSalesVolumeVsYAGO
      profitByUnit
      profitByVolume
      priceByVolume
      priceByUnitSDV
      dandAByUnit
      cogSByUnit
      cogSByVolume
      gramsPerUnit
      growthSOMByYear
      perGrowthSOMByYear
      priceByUnit
      salesAmount
      salesUnits
      salesVolume
      somSalesAmount
      somSalesUnits
      somSalesVolume
    }
    absPeriodCompare {
      salesAmountVsYAGO
      perSalesAmountVsYAGO
      salesUnitVsYAGO
      perSalesUnitVsYAGO
      salesVolumeVsYAGO
      perSalesVolumeVsYAGO
      profitByUnit
      profitByVolume
      priceByVolume
      priceByUnitSDV
      dandAByUnit
      cogSByUnit
      cogSByVolume
      gramsPerUnit
      growthSOMByYear
      perGrowthSOMByYear
      priceByUnit
      salesAmount
      salesUnits
      salesVolume
      somSalesAmount
      somSalesUnits
      somSalesVolume
    }
      }
    }`,
	};
};

/* Creates a GraphQL query to get common anchor product filters for Revenue Map Dashboard.
 * @param {IFilterPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const REVENUE_MAP_PRODUCT_FILTER_QUERY = (payload: IFilterPayload, userId: string) => {
	return {
		query: `{
      revenueMapFilter(country: "${payload.country}"
      UserId: "${userId}"
			)
		}`,
	};
};
