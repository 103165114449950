import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Dropdown from "../Dropdown";
import { DropdownTitle } from "../DashboardFilters/DashboardFilters.Styled";
import CustomDateRangePicker from "../UI-components/DateRangePicker";
import { dataFormat } from "../../util/helper";
import _ from "lodash";
import { DateTime } from "luxon";
import { fetchPeriodViewDates, getGeoLevel2 } from "../../util/services";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { defaultCalendarDates } from "../../util/config";
import { dialog } from "../../store/actions/dialog.action";
export const initialState = {
	country: null,
	geoLevel: null,
	geoLevel2: null,
	channel: null,
	periodView: ["na"],
};
export const dateRange = [defaultCalendarDates.startDate, defaultCalendarDates.endDate];
const GeoFiltersV2: React.FC<{
	data;
	onChangeDate?;
	defaultDate?;
	onSelectFilters;
	defaultFilters?;
	showDatePicker?: boolean;
	showPeriodView?: boolean;
	clearFilter?: boolean;
	apiPath?;
	showLoader?;
	setIsCountryManuallyChangedFlag?;
}> = ({
	data,
	onChangeDate,
	defaultDate,
	defaultFilters,
	onSelectFilters,
	showDatePicker = true,
	showPeriodView = false,
	clearFilter,
	apiPath,
	showLoader,
	setIsCountryManuallyChangedFlag,
}) => {
	const dispatch = useDispatch();
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const [countries, setCountries] = useState([]);
	const [geoLevels, setGeoLevels] = useState([]);
	const [channels, setChannels] = useState([]);
	const [geoLevel2, setGeoLevel2] = useState([]);
	const [selectedDate, setSelectedDate] = useState(defaultDate || dateRange);
	const [selectedGeoFilters, setSelectedGeoFilters] = useState({
		...initialState,
	});
	const [disabledGeoFilters, setDisabledGeoFilters] = useState({
		country: false,
		geoLevel: true,
		geoLevel2: true,
		channel: true,
		periodView: true,
	});
	const [selectedPeriodView, setSelectedPeriodView] = useState<string[]>([]);

	useEffect(() => {
		if (defaultDate) {
			setSelectedDate(defaultDate);
		}
	}, [defaultDate]);
	useEffect(() => {
		if (selectedGeoFilters.country && countries.length > 0) {
			updateGeoFIlters("", selectedGeoFilters.country);
		}
	}, [selectedGeoFilters.country, countries]);
	useEffect(() => {
		if (defaultFilters && defaultFilters.country && userData?.id) {
			let periodView = ["na"];
			if (defaultFilters.periodView) {
				if (_.isArray(defaultFilters.periodView)) {
					periodView = defaultFilters.periodView;
				} else {
					periodView = [defaultFilters.periodView];
				}
			}
			setSelectedPeriodView(periodView);
			if (defaultFilters.country !== selectedGeoFilters.country) {
				if (_.isArray(countries) && !countries.length) {
					fetchGeoFiltersData(apiPath);
				}
				setSelectedGeoFilters({
					...initialState,
					periodView,
					country: defaultFilters.country,
				});
			} else {
				fetchGeoFiltersData(apiPath, defaultFilters.country);
			}
		} else if (userData?.id && !selectedGeoFilters.country) {
			updateGeoFIlters(apiPath);
		}
	}, [defaultFilters, userData]);
	useEffect(() => {
		if (_.isArray(geoLevels)) {
			if (defaultFilters && defaultFilters.geoLevel) {
				onChangeGeoLevel(defaultFilters.geoLevel);
			} else {
				const deafultGeoLevel = geoLevels.find((i) => i.defaultVal);
				if (deafultGeoLevel && deafultGeoLevel.value) {
					onChangeGeoLevel([deafultGeoLevel.value]);
				}
			}
		}
	}, [geoLevels]);
	useEffect(() => {
		if (selectedGeoFilters && selectedGeoFilters.country) {
			onSelectFilters({ ...selectedGeoFilters });
		}
	}, [selectedGeoFilters]);

	const updateGeoFIlters = (dashboardKey?, country?) => {
		const geoLevelSessionData = JSON.parse(sessionStorage.getItem("geoLevelsFilters"));
		const dt1 = DateTime.fromJSDate(new Date());
		const dt2 =
			geoLevelSessionData && geoLevelSessionData.expiresAt ? DateTime.fromJSDate(new Date(geoLevelSessionData.expiresAt)) : DateTime.fromJSDate(new Date());
		if (geoLevelSessionData && _.isArray(geoLevelSessionData.allGeoLevels) && dt1 < dt2) {
			if (country) {
				const filteredGeoLevels = geoLevelSessionData.allGeoLevels.find((x) => x.label === country);
				if (filteredGeoLevels) {
					setGeoLevels(filteredGeoLevels.geoLevels);
				}
			}
			if (dashboardKey) {
				const countries = _.isArray(geoLevelSessionData.allGeoLevels)
					? geoLevelSessionData.allGeoLevels.map((x) => ({
							label: x.label,
							value: x.label,
					  }))
					: [];
				setCountries(countries);
			}
		} else {
			fetchGeoFiltersData(dashboardKey, country);
		}
	};

	const fetchGeoFiltersData = (dashboardKey?, country?) => {
		showLoader(true);
		getGeoLevel2(userData.id, dashboardKey)
			.then((response) => {
				const expiresAt = DateTime.fromJSDate(new Date()).plus({ hours: 4 });
				sessionStorage.setItem("geoLevelsFilters", JSON.stringify({ expiresAt, allGeoLevels: response.data }));
				if (country) {
					const filteredGeoLevels = _.isArray(response.data) ? response.data.find((x) => x.label === country) : null;
					setGeoLevels(filteredGeoLevels.geoLevels);
				}
				if (dashboardKey) {
					setGeoLevels([]);
				}
				const countries = _.isArray(response.data) ? response.data.map((x) => ({ label: x.label, value: x.label })) : [];
				setCountries(countries);
				showLoader(false);
			})
			.catch((e) => {
				showLoader(false);
			});
	};
	useEffect(() => {
		if (clearFilter) {
			setSelectedDate(dateRange);
			setSelectedGeoFilters({ ...initialState });
			setChannels([]);
			setGeoLevel2([]);
			setGeoLevels([]);
			setDisabledGeoFilters({
				country: false,
				geoLevel: true,
				geoLevel2: true,
				channel: true,
				periodView: true,
			});
		}
	}, [clearFilter]);
	const onSelectDate = (date) => {
		if (date.filter((dateValue) => dateValue !== null).length === 2) {
			setSelectedDate(date);
			const formattedDate: string[] = [];
			for (let dateValue of date) {
				if (dateValue) formattedDate.push(dataFormat(dateValue, "MM/dd/yyyy"));
			}
			onChangeDate(formattedDate.join("-"));
		}
	};
	const onChangeGeoLevel = (geoLevel) => {
		if (geoLevels && _.isArray(geoLevels) && geoLevels.length > 0) {
			let result;
			if (_.isArray(geoLevel)) {
				result = geoLevels.find((item) => item.value === geoLevel[0]);
			} else {
				result = geoLevels.find((item) => item.value === geoLevel);
			}
			const newDisableData = { ...disabledGeoFilters, geoLevel: false, periodView: false };
			const newSelectedData = { ...selectedGeoFilters, geoLevel };
			if (result && _.isArray(result.channels)) {
				if (result.channels.length === 1) {
					if (_.findIndex(result.channels, (item: any) => item.value === "NA") !== -1) newDisableData.channel = true;
					else newDisableData.channel = false;
				} else {
					newDisableData.channel = false;
				}
				if (defaultFilters && defaultFilters.channel) {
					newSelectedData.channel = result.channels.filter((x) => defaultFilters.channel.findIndex((y) => y === x.value) !== -1).map((item) => item.value);
				} else {
					const deafultChannel = result.channels.find((i) => i.defaultVal);
					if (deafultChannel) {
						newSelectedData.channel = result.channels.filter((x) => x.defaultVal).map((item) => item.value);
					} else {
						newSelectedData.channel = result.channels.map((item) => item.value);
					}
				}
				setChannels(result.channels);
			}
			if (result && _.isArray(result.geoLevel2s)) {
				if (result.geoLevel2s.length === 1) {
					if (_.findIndex(result.geoLevel2s, (item: any) => item.value === "NA") !== -1) newDisableData.geoLevel2 = true;
					else newDisableData.geoLevel2 = false;
				} else {
					newDisableData.geoLevel2 = false;
				}
				if (defaultFilters && defaultFilters.geoLevel2) {
					newSelectedData.geoLevel2 = result.geoLevel2s
						.filter((x) => defaultFilters.geoLevel2.findIndex((y) => y === x.value) !== -1)
						.map((item) => item.value);
				} else {
					const defaultGeoLevel2 = result.geoLevel2s.find((i) => i.defaultVal);
					if (defaultGeoLevel2) {
						newSelectedData.geoLevel2 = result.geoLevel2s.filter((x) => x.value === defaultGeoLevel2.value).map((item) => item.value);
					} else {
						newSelectedData.geoLevel2 = null;
					}
				}
				setGeoLevel2(result.geoLevel2s);
			}
			setDisabledGeoFilters(newDisableData);
			setSelectedGeoFilters(newSelectedData);
			onSelectFilters({ ...newSelectedData });
		}
	};
	const onChangeOption = (key, val) => {
		if (_.isArray(val) && val.length === 0) {
			val = null;
		}
		const payload = { ...selectedGeoFilters, [key]: val };
		if (key === "channel") {
			const geoLevel = geoLevels.find((item) => item.value === selectedGeoFilters.geoLevel[0]);
			const geoLevel2s = geoLevel.geoLevel2s.filter((x) => x.parentField.toLowerCase() === key && _.includes(val, x.parent));
			setGeoLevel2(geoLevel2s);
			const defaultGeoLevel2 = geoLevel2s.find((i) => i.defaultVal);
			if (defaultGeoLevel2) {
				payload.geoLevel2 = geoLevel2s.filter((x) => x.value === defaultGeoLevel2.value).map((item) => item.value);
			} else {
				payload.geoLevel2 = null;
			}
		}
		setSelectedGeoFilters(payload);
		onSelectFilters({ ...payload });
	};

	const onChangePeriodView = (periodView) => {
		setSelectedPeriodView(periodView);
		setSelectedGeoFilters({ ...selectedGeoFilters, periodView });
		if (periodView[0] !== "na") {
			showLoader(true);
			const payload = {
				period: periodView[0],
				country: selectedGeoFilters.country,
			};
			fetchPeriodViewDates(apiPath, payload)
				.then((res) => {
					const response = res.data;
					if (res.status === 200 && response?.startDate && response?.endDate) {
						const startDate = DateTime.fromString(response.startDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						const endDate = DateTime.fromString(response.endDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						setSelectedDate([startDate, endDate]);
						onChangeDate(`${startDate}-${endDate}`);
					} else {
						dispatch(dialog("Data is not available for selected country!", "Period View", true));
					}
					showLoader(false);
				})
				.catch((e) => {
					dispatch(dialog(e.message, "Period View", true));
					showLoader(false);
				});
		}
	};

	return (
		<Grid container spacing={2} columns={17}>
			<Grid item xs={12} md={2} sm={2} lg={2}>
				<DropdownTitle>{data.country.title}</DropdownTitle>
				<Dropdown
					disabled={disabledGeoFilters.country}
					data={countries}
					onChange={(data) => {
						setSelectedGeoFilters({ ...initialState, country: data[0] });
						setIsCountryManuallyChangedFlag(true);
					}}
					defaultOption={[selectedGeoFilters.country]}
				/>
			</Grid>
			<Grid item xs={12} md={2} sm={2} lg={2}>
				<DropdownTitle>{data.geoLevel.title}</DropdownTitle>
				<Dropdown
					disabled={disabledGeoFilters.geoLevel}
					data={geoLevels}
					onChange={(dt) => onChangeGeoLevel(dt)}
					defaultOption={selectedGeoFilters.geoLevel ?? ""}
					placeholder={data.geoLevel.placeholder}
				/>
			</Grid>
			<Grid item xs={12} md={3} sm={3} lg={2}>
				<DropdownTitle>{data.channel.title}</DropdownTitle>
				<Dropdown
					disabled={disabledGeoFilters.channel}
					data={channels}
					onChange={(data) => onChangeOption("channel", data)}
					defaultOption={selectedGeoFilters.channel ?? ["empty"]}
					placeholder={data.channel.placeholder}
					multiple={data.channel.multiple}
					allOption={data.channel.all}
				/>
			</Grid>
			<Grid item xs={12} md={3} sm={3} lg={2}>
				<DropdownTitle>{data.geoLevel2.title}</DropdownTitle>
				<Dropdown
					disabled={disabledGeoFilters.geoLevel2}
					data={geoLevel2}
					onChange={(data) => onChangeOption("geoLevel2", data)}
					defaultOption={selectedGeoFilters.geoLevel2 ?? ["empty"]}
					placeholder={data.geoLevel2.placeholder}
				/>
			</Grid>
			{showPeriodView && (
				<Grid item xs={12} md={3} sm={3} lg={2}>
					<DropdownTitle>{data.periodView.title}</DropdownTitle>
					<Dropdown
						disabled={disabledGeoFilters.periodView}
						data={data.periodView.options}
						onChange={(data) => onChangePeriodView(data)}
						defaultOption={selectedGeoFilters.periodView ?? ["empty"]}
						placeholder={data.periodView.placeholder}
						sort={{ enable: false }}
						search={{ enable: false }}
					/>
				</Grid>
			)}
			{showDatePicker && (
				<Grid item xs={12} md={7} sm={7} lg={4}>
					<DropdownTitle>Date Range</DropdownTitle>
					<CustomDateRangePicker defaultDate={selectedDate} callback={onSelectDate} disabled={showPeriodView ? selectedPeriodView[0] !== "na" : false} />
				</Grid>
			)}
		</Grid>
	);
};
export default GeoFiltersV2;
