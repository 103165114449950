const USER_CREATION = "/user-creation";
const HOME = "/home";
const PRICING_AND_PPA = "/pricing-and-pack-price-architecture";
const INTEGRATED_STRATEGY = "/integrated-Strategy";
const PROMO_AND_TRADE_MANAGEMENT = "/promotions-and-trade-management";
const MIX_MANAGEMENT = "/mix-management";
const ALERT_AND_NOTIFICATION = "/system-alert-notifications";
const USER_SETTINGS = "/user-settings";
const API_PREDICTIVE_DASHBOARD = "/api-vs-volume";
const PROFIT_PARABOLA_DASHBOARD = "/profit-parabola-dashboard";
const SELL_OUT_DASHBOARD = "/sell-out-dashboard";
const SUPER_MARKET = "/sales-by-price-point";
const HISTORIC_PRICE = "/historic-price";
const MACRO_ECON = "/macro-econ-dashboard";
const MMPW_TEST = "/mmpw-test";
const USER_LIST = "/users";
const USER_CREATE = "/user";
const USER_EDIT = "/user/:id";
const REVENUE_MAP = "/revenue-map";
const ELASTICITY_PRICING_TRACK = "/elasticity-pricing-track";
const PRICING_POTENTIAL = "/pricing-potential";
const BRAND_LADDER = "/brand-ladder";
const PACK_ECONOMICS = "/pack-economics";
const PRICE_SETTING_TOOL = "/price-setting-tool";
const PRICE_RANGE_PIANO = "/price-range-piano";
const PRICE_LADDER = "/price-ladder";
const LOGIN_CALLBACK = "/signin-oidc";
const USAGE_ACTIVITY_REPORT = "/usage-activity-report";
const USAGE_COUNT_REPORT = "/usage-count-report";

export {
	USER_CREATION,
	HOME,
	USER_EDIT,
	PRICING_AND_PPA,
	INTEGRATED_STRATEGY,
	ALERT_AND_NOTIFICATION,
	USER_SETTINGS,
	SUPER_MARKET,
	HISTORIC_PRICE,
	PROFIT_PARABOLA_DASHBOARD,
	API_PREDICTIVE_DASHBOARD,
	SELL_OUT_DASHBOARD,
	MACRO_ECON,
	MMPW_TEST,
	USER_LIST,
	USER_CREATE,
	REVENUE_MAP,
	ELASTICITY_PRICING_TRACK,
	PRICING_POTENTIAL,
	BRAND_LADDER,
	PACK_ECONOMICS,
	PRICE_SETTING_TOOL,
	PRICE_RANGE_PIANO,
	PRICE_LADDER,
	PROMO_AND_TRADE_MANAGEMENT,
	MIX_MANAGEMENT,
	LOGIN_CALLBACK,
	USAGE_ACTIVITY_REPORT,
	USAGE_COUNT_REPORT,
};
