import React, { useEffect, useState } from "react";
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveLabel, removeLabels, removeSeriesLabels } from "../../store/actions/common.action";
import BarChartInTooltip from "../../pages/PriceRangePiano/components/ShareOfMarketPiano/components/PriceRangeGraph/TooltipComponent/BarChartInTooltip";
import BubbleChartTooltip from "../../pages/RevenueMap/components/BubbleChartTooltip/BubbleChartTooltip";
import DrillDownView from "../../pages/RevenueMap/components/BubbleChartTooltip/DrillDownView";

function getChildTooltipComponents(tooltipList, parentTooltip) {
	const childTooltipList = tooltipList
		.filter((tooltip) => tooltip.id.includes(parentTooltip.id) && tooltip.isChild && !parentTooltip.isChild)
		.map((tooltip) => {
			return {
				id: tooltip.id,
				x: tooltip.x,
				y: tooltip.y,
			};
		});
	return childTooltipList;
}

function getParentXPosition(tooltipList, t) {
	let x = t.x;
	if (t.id.includes("price-range-share-of-market-piano") && t.chart.series.initialType !== "spline" && t.chart.series?.chart?.containerWidth) {
		x = t.chart.series.chart.containerWidth - x < 330 ? t.chart.series.chart.containerWidth - 330 : x;
	}
	if (t.isChild) {
		const parentTooltipIndex: number = tooltipList.findIndex((tooltip) => t.id.includes(tooltip.id) && !tooltip.isChild);
		const parentTooltip = tooltipList[parentTooltipIndex];
		x = parentTooltip.chart.series.chart.containerWidth - parentTooltip.x < 330 ? parentTooltip.chart.series.chart.containerWidth - 330 : x;
	}

	return x;
}

const HighchartContainer: React.FC<{
	chartRef?;
	id;
	children;
}> = ({ chartRef, id, children }) => {
	const open = useSelector((state: any) => state.common.sidebarOpen);
	const tooltips = useSelector((state: any) => state.common.highChartTooltips);
	const dispatch = useDispatch();
	const updateXarrow = useXarrow();
	const [clickEventData, setClickEventData] = useState<any>({});
	useEffect(() => {
		if (chartRef && chartRef.current && chartRef.current.chart) {
			const chart = chartRef.current.chart;
			chart.update({
				plotOptions: {
					series: {
						cursor: "pointer",
						point: {
							events: {
								click: function () {
									setClickEventData({ ...this });
								},
							},
						},
						events: {
							legendItemClick: function (e) {
								dispatch(removeSeriesLabels([...chart.series[e.target.index].data], id));
							},
						},
					},
				},
			});
		}
	}, [chartRef]);

	useEffect(() => {
		if (Object.keys(clickEventData).length > 0) {
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				dispatch(addRemoveLabel([clickEventData], id, tooltips, chart));
				setClickEventData({});
			}
		}
	}, [clickEventData]);

	useEffect(() => {
		return () => {
			dispatch(removeLabels(id));
		};
	}, []);

	useEffect(() => {
		dispatch(removeLabels(id));
	}, [open]);

	useEffect(() => {
		window.addEventListener("resize", hideToolTip);
		return () => {
			window.removeEventListener("resize", hideToolTip);
		};
	}, []);

	const hideToolTip = () => {
		dispatch(removeLabels(id));
	};

	const getChartComponent = (tooltip, key) => {
		const fontSize = chartRef?.current?.chart?.tooltip?.options?.style?.fontSize || tooltip?.parentChart?.options?.tooltip?.style?.fontSize || "12px";
		if (tooltip.id.includes("price-range-share-of-market-piano") && tooltip.chart.series.initialType !== "spline") {
			return (
				<BarChartInTooltip
					data={tooltip.chart.customData}
					category={tooltip.chart.parentCategory ?? tooltip.chart.category}
					color={tooltip.chart.color}
					subCategory={tooltip.isChild ? tooltip.chart.category : tooltip.chart.series.name}
					id={id}
					parentId={tooltip.id}
					fontSize={fontSize}
				/>
			);
		} else if (tooltip.id.includes("revenue-map-quadrant-intensity-graph")) {
			const childTooltipCoponent = getChildTooltipComponents(tooltips[id], tooltip);
			if (tooltip.isChild) {
				return <DrillDownView childData={tooltip.chart.childData} childColumn={tooltip.chart.childColumn} />;
			}
			return (
				<BubbleChartTooltip
					chart={tooltip.chart}
					id={key}
					parentId={tooltip.id}
					name={tooltip.chart.name}
					x={tooltip.chart.x}
					y={tooltip.chart.y}
					varSize={tooltip.chart.z}
					color={tooltip.chart.color}
					isOpened={childTooltipCoponent.length !== 0 ? true : false}
					childData={tooltip.chart.childData}
				/>
			);
		} else {
			return <span dangerouslySetInnerHTML={{ __html: tooltip.tooltip }}></span>;
		}
	};

	const getChildTooltipIndicator = (parentTooltip, parentIndex) => {
		const childTooltipCoponent = getChildTooltipComponents(tooltips[id], parentTooltip);
		return childTooltipCoponent.map((ct, j) => {
			if (parentTooltip.id.includes("revenue-map-quadrant-intensity-graph") && childTooltipCoponent.length > 0) {
				return <></>;
			} else {
				const fontSize: string = parentTooltip.parentChart?.options?.tooltip?.style?.fontSize || "12px";
				const affectedTopPosition = (parseInt(fontSize.slice(0, -2)) - 12) * 3;
				const IsPieBubbleChart = parentTooltip.chart.series.type === "pie" || parentTooltip.chart.series.type === "bubble";
				return (
					<div
						key={`tooltip-div-${parentIndex}-${j}`}
						id={ct.id}
						style={{
							position: "absolute",
							left: IsPieBubbleChart ? 0 : ct.x - parentTooltip.x + 10,
							top: IsPieBubbleChart ? 0 : ct.y - parentTooltip.y + 47 + affectedTopPosition,
							zIndex: 5,
							width: 10,
							height: 10,
							borderRadius: "50%",
							background: IsPieBubbleChart ? parentTooltip.chart.color : parentTooltip.chart.series.color,
						}}
					></div>
				);
			}
		});
	};

	const getXArrowEndPoint = (tooltip) => {
		if (tooltip.id.includes("revenue-map-quadrant-intensity-graph") && tooltip.isChild) {
			return `${tooltip.id}-expandBtn`;
		} else {
			return `${tooltip.id}`;
		}
	};

	return (
		<Xwrapper>
			<div style={{ position: "relative" }}>
				{Object.keys(tooltips).length > 0 &&
					tooltips[id] &&
					tooltips[id].map((t, i) => (
						<div key={`div-${i}`}>
							{!t.isChild && (
								<div
									id={t.id}
									style={{
										position: "absolute",
										left: t.x,
										top: t.y,
										zIndex: 5,
										width: 10,
										height: 10,
										borderRadius: "50%",
										background: t.chart.series.type === "pie" || t.chart.series.type === "bubble" ? t.chart.color : t.chart.series.color,
									}}
								></div>
							)}
							<Draggable onDrag={updateXarrow} onStop={updateXarrow}>
								<div
									className="tooltip"
									id={`${t.id}-${i}`}
									style={{
										position: "absolute",
										left: getParentXPosition(tooltips[id], t) - 40,
										top: t.y - 70,
										zIndex: 5,
										borderColor: t.chart.series.type === "pie" || t.chart.series.type === "bubble" ? t.chart.color : t.chart.series.color,
										fontSize: chartRef?.current?.chart?.tooltip?.options?.style?.fontSize || t?.parentChart?.options?.tooltip?.style?.fontSize || "12px",
										width: "auto",
									}}
								>
									{getChildTooltipIndicator(t, i)}
									{getChartComponent(t, id)}
								</div>
							</Draggable>
							<Xarrow
								start={`${t.id}-${i}`}
								end={getXArrowEndPoint(t)}
								zIndex={5}
								color={t.chart.series.type === "bubble" || t.chart.series.type === "pie" ? t.chart.color : t.chart.series.color}
								showHead={false}
								strokeWidth={2}
								dashness={true}
								headShape={"circle"}
							/>
						</div>
					))}
			</div>
			{children}
		</Xwrapper>
	);
};
export default HighchartContainer;
