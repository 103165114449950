import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import DashboardActions from "../../components/DashboardActions";
import DashboardSourceList from "../../components/DashboardSourceList";
import { pageList } from "../../mocks/common";
import dataObject from "../../mocks/revenueMap";
import { removeMMPWFromLocal } from "../../util/helper";
import QuadrantIntensityGraph from "./components/QuadrantIntensityGraph";
import RevenueMapByType from "./components/RevenueMapByType";
import TopFilters from "./components/TopFilters";
import { quadrantIntensityLoader } from "./store/revenueMap.action";
import { Grid } from "@mui/material";
import GlobalNotifications from "../../components/GlobalNotificationContainer/GlobalNotificationContainer";

const RevenueMap: React.FC<{}> = () => {
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const reduxState = useSelector((state: RootStateOrAny) => state);
	const RevenueMap = useSelector((state: RootStateOrAny) => state.RevenueMap);
	const [appliedFilters, setAppliedFilters] = useState({} as any);
	const [appliedLocalFilters, setAppliedLocalFilters] = useState({} as any);
	const [defaultFilters, setDefaultFilters] = useState({});
	const [disableDownloadButton, setDisableDownloadButton] = useState(true);

	const dispatch = useDispatch();

	const search = window.location.search;
	const [urlParams] = useState(new URLSearchParams(search));
	const recordId = parseInt(urlParams.get("id"));
	useEffect(() => {
		if (userData && !recordId) {
			const permissionCountries: string[] = userData.permissionCountry.split(",");
			const permissionCategories: string[] = userData.permissionCategory.split(",");
			let defaultFilterConfiguration = dataObject.defaultRevenueMapFilterConfigurations.find(
				(item) => permissionCountries.includes(item.country) && item.category.every((category) => permissionCategories.includes(category))
			);
			if (defaultFilterConfiguration && Object.keys(defaultFilterConfiguration).length) {
				defaultFilterConfiguration["startDate"] = defaultFilterConfiguration.date.split("-")[0];
				defaultFilterConfiguration["endDate"] = defaultFilterConfiguration.date.split("-")[1];
				setDefaultFilters(defaultFilterConfiguration);
				setAppliedFilters(defaultFilterConfiguration);
			}
		}
	}, [userData, dataObject.defaultRevenueMapFilterConfigurations]);

	const onApplyFilter = async (filters) => {
		setDisableDownloadButton(false);
		setDefaultFilters({});
		dispatch(quadrantIntensityLoader(true));
		removeMMPWFromLocal("revenue-map-quadrant-intensity-graph");
		setAppliedFilters(filters);
	};

	const onApplyLocalFilter = async (localFilters) => {
		setDefaultFilters({});
		setAppliedLocalFilters(localFilters);
	};
	const isDisabled: boolean = false;
	const getSaveDashBoardsData = (data) => {
		if (data.filters) {
			let savedDashboardData = { filters: JSON.parse(data.filters) };
			setDisableDownloadButton(false);
			setDefaultFilters({
				...savedDashboardData?.filters?.global,
				views: savedDashboardData?.filters.localFilters?.views,
				variables: savedDashboardData?.filters.localFilters?.variables,
			});
			setAppliedFilters(savedDashboardData?.filters?.global);
		}
	};

	return (
		<>
			{userData && (
				<DashboardActions
					disabled={isDisabled}
					title="Revenue Map"
					dashboardKey={pageList[6].id}
					callback={getSaveDashBoardsData}
					data={{
						filters: {
							global: { ...appliedFilters },
							localFilters: { ...appliedLocalFilters },
						},
						mmpwKey: "revenue-map-quadrant-intensity-graph",
					}}
					disableDownloadButton={disableDownloadButton || reduxState.common.loader || RevenueMap.quadrantIntensityLoader}
				>
					<Grid container spacing={2} className="m-b-20">
						<GlobalNotifications pageName={pageList[6].value} />
					</Grid>
					<TopFilters callback={onApplyFilter} data={dataObject} defaultFilters={defaultFilters} />
					<QuadrantIntensityGraph filters={appliedFilters} />
					<RevenueMapByType filters={appliedFilters} defaultFilters={defaultFilters} callback={onApplyLocalFilter} />
					<DashboardSourceList category={appliedFilters.category} dashboardName={pageList[6].id} appliedFilters={appliedFilters} />
				</DashboardActions>
			)}
		</>
	);
};

export default RevenueMap;
