import React, { useContext, useEffect, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import UserCreation from "../pages/User/UserCreation";
import Home from "../pages/Home";
import PriceArchitecture from "../pages/PriceArchitecture";
import MixManagement from "../pages/MixManagement";
import IntegratedStrategy from "../pages/IntegratedStrategy";
import TradeManagement from "../pages/TradeManagement";
import AlertAndNotification from "../pages/AlertAndNotification";
import UserSettings from "../pages/User/UserSettings";
import ProfitParabolaDashboard from "../pages/ProfitParabolaDashboard";
import APIPredictiveDashboard from "../pages/APIPredictiveDashboard";
import SupermarketDashboard from "../pages/SupermarketDashboard";
import HistoricPriceAndCrossPriceElasticity from "../pages/HistoricPriceAndCrossPriceElasticity";
import MMPWTest from "../pages/MMPWTest";
import MacroEconDashboard from "../pages/MacroEconDashboard";
import UserList from "../pages/Admin/UserList";
import CreateUser from "../pages/Admin/UserCreation";
import RevenueMap from "../pages/RevenueMap";
import SellOutDashboard from "../pages/SellOutDashboard";
import ElasticityPricingTrack from "../pages/ElasticityPricingTrack";
import PricingPotential from "../pages/PricingPotential";
import BrandLadder from "../pages/BrandLadder";
import PredictivePackEconomics from "../pages/PredictivePackEconomics";
import PriceSettingTool from "../pages/PriceSettingTool";
import PriceRangePiano from "../pages/PriceRangePiano";
import PriceLadder from "../pages/PriceLadder";
import PrivateRoute from "./PrivateRoute";
import UsageReport2 from "../pages/UserReport2";
import Reporting from "../pages/Reporting";
import {
	USER_CREATION,
	HOME,
	USER_EDIT,
	PRICING_AND_PPA,
	INTEGRATED_STRATEGY,
	ALERT_AND_NOTIFICATION,
	USER_SETTINGS,
	PROFIT_PARABOLA_DASHBOARD,
	API_PREDICTIVE_DASHBOARD,
	SELL_OUT_DASHBOARD,
	SUPER_MARKET,
	HISTORIC_PRICE,
	MMPW_TEST,
	MACRO_ECON,
	USER_LIST,
	USER_CREATE,
	REVENUE_MAP,
	ELASTICITY_PRICING_TRACK,
	PRICING_POTENTIAL,
	BRAND_LADDER,
	PACK_ECONOMICS,
	PRICE_SETTING_TOOL,
	PRICE_RANGE_PIANO,
	PRICE_LADDER,
	MIX_MANAGEMENT,
	PROMO_AND_TRADE_MANAGEMENT,
	USAGE_ACTIVITY_REPORT,
	USAGE_COUNT_REPORT,
} from "./CONSTANTS";
import { useOktaAuth } from "@okta/okta-react";
import { EnvironmentContext } from "../App";

const RouterConfig = () => {
	const { authState, oktaAuth } = useOktaAuth();
	const environmentVariables = useContext(EnvironmentContext);
	const [isFetchUserStarted, setIsFetchUserStarted] = useState(false);
	const fetchUserDetails = async () => {
		try {
			const user = await oktaAuth.getUser();
			environmentVariables.setEnvironmentVariables({
				...environmentVariables.environmentVariables,
				LOGGEDIN_USER: user.email ?? "",
			});
			setIsFetchUserStarted(false);
		} catch (error) {
			// Handle any errors
			console.error("Error fetching user details:", error);
		}
	};
	useEffect(() => {
		if (authState?.isAuthenticated && !environmentVariables.environmentVariables.LOGGEDIN_USER && !isFetchUserStarted) {
			setIsFetchUserStarted(true);
			fetchUserDetails();
		}
	}, [authState]);
	return (
		<Switch>
			<PrivateRoute uniqueKey="user_setting" path={USER_SETTINGS} component={UserSettings} />

			<PrivateRoute uniqueKey="home" exact path="/" component={Home} />
			<PrivateRoute uniqueKey="home" exact path={HOME} component={Home} />

			<PrivateRoute uniqueKey="pricing_and_ppa" exact path={PRICING_AND_PPA} component={PriceArchitecture} />
			<PrivateRoute uniqueKey="api_vs_volume" path={API_PREDICTIVE_DASHBOARD} component={APIPredictiveDashboard} />
			<PrivateRoute uniqueKey="price_range_piano" path={PRICE_RANGE_PIANO} component={PriceRangePiano} />
			{/* <PrivateRoute uniqueKey="pack_economics" path={PACK_ECONOMICS} component={PredictivePackEconomics} /> */}
			<PrivateRoute uniqueKey="price_ladder" path={PRICE_LADDER} component={PriceLadder} />
			{/* <PrivateRoute uniqueKey="sellout_price_tracking" path={SELL_OUT_DASHBOARD} component={SellOutDashboard} /> */}
			{/* <PrivateRoute uniqueKey="brand_ladder" path={BRAND_LADDER} component={BrandLadder} /> */}
			<PrivateRoute uniqueKey="revenue_map" path={REVENUE_MAP} component={RevenueMap} />

			<PrivateRoute uniqueKey="promo_and_trade_management" exact path={PROMO_AND_TRADE_MANAGEMENT} component={TradeManagement} />
			{/* <PrivateRoute uniqueKey="sales_by_price_point" path={SUPER_MARKET} component={SupermarketDashboard} /> */}
			{/* <PrivateRoute uniqueKey="profit_parabola" path={PROFIT_PARABOLA_DASHBOARD} component={ProfitParabolaDashboard} /> */}

			<PrivateRoute uniqueKey="mix_management" exact path={MIX_MANAGEMENT} component={MixManagement} />

			{/* <PrivateRoute uniqueKey="integrated_strategy" exact path={INTEGRATED_STRATEGY} component={IntegratedStrategy} /> */}
			{/* <PrivateRoute uniqueKey="macro_econ" path={MACRO_ECON} component={MacroEconDashboard} /> */}
			{/* <PrivateRoute uniqueKey="price_setting_tool" path={PRICE_SETTING_TOOL} component={PriceSettingTool} /> */}
			{/* <PrivateRoute uniqueKey="hpcpe" exact path={HISTORIC_PRICE} component={HistoricPriceAndCrossPriceElasticity} /> */}
			{/* <PrivateRoute uniqueKey="pricing_potential" path={PRICING_POTENTIAL} component={PricingPotential} /> */}
			<PrivateRoute uniqueKey="ept" path={ELASTICITY_PRICING_TRACK} component={ElasticityPricingTrack} />

			<PrivateRoute uniqueKey="usage_count_report" path={USAGE_COUNT_REPORT} component={Reporting} />
			<PrivateRoute uniqueKey="usage_activity_report" path={USAGE_ACTIVITY_REPORT} component={UsageReport2} />

			<PrivateRoute uniqueKey="system_alerts_notifications" exact path={ALERT_AND_NOTIFICATION} component={AlertAndNotification} />

			<PrivateRoute uniqueKey="user_list" path={USER_LIST} component={UserList} />
			<PrivateRoute uniqueKey="user_edit" exact path={USER_EDIT} component={CreateUser} />
			<PrivateRoute uniqueKey="user_create" exact path={USER_CREATE} component={CreateUser} />

			{/* <PrivateRoute exact path={USER_CREATION} component={UserCreation} /> */}
			{/* <PrivateRoute uniqueKey="mmpw_test" path={MMPW_TEST} component={MMPWTest} /> */}
			<Redirect to={"/"} />
		</Switch>
	);
};
export default RouterConfig;
